<template>
  <div class="language-selector">
    <b-dropdown 
      variant="link" 
      no-caret
      class="lang-dropdown"
      style="border: 1px solid #d8d6de !important;"
    >
      <template #button-content>
        <template v-if="hasFlag(currentLocale)">
          <img 
            :src="require(`@/assets/images/flags/${currentLocale}.png`)" 
            :alt="currentLocale"
            class="flag-icon"
          />
        </template>
        <template v-else>
          <span class="flag-emoji">{{ getEmojiFlag(currentLocale) }}</span>
        </template>
        <span class="ml-1">{{ getCurrentLanguageName(currentLocale) }}</span>
      </template>
      
      <b-dropdown-item
        v-for="locale in availableLocales"
        :key="locale"
        @click="changeLocale(locale)"
        :active="locale === currentLocale"
      >
        <template v-if="hasFlag(locale)">
          <img 
            :src="require(`@/assets/images/flags/${locale}.png`)" 
            :alt="locale"
            class="flag-icon"
          />
        </template>
        <template v-else>
          <span class="flag-emoji">{{ getEmojiFlag(locale) }}</span>
        </template>
        <span class="ml-1">{{ getCurrentLanguageName(locale) }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',

  props: {
    community: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      languageNames: {
        ca: 'Català',
        es: 'Español',
        en: 'English',
        fr: 'Français',
        de: 'Deutsch',
        it: 'Italiano',
        pt: 'Português',
        nl: 'Nederlands',
        ar: 'العربية',
        zh: '中文',
        bg: 'Български',
        da: 'Dansk',
        el: 'Ελληνικά',
        hr: 'Hrvatski',
        hu: 'Magyar',
        pl: 'Polski',
        ja: '日本語',
        ru: 'Русский'
      },
      countryFlags: {
        ca: 'ES', // Using Spain as fallback for Catalan
        es: 'ES',
        en: 'GB',
        fr: 'FR',
        de: 'DE',
        it: 'IT',
        pt: 'PT',
        nl: 'NL',
        ar: 'SA',
        zh: 'CN',
        bg: 'BG',
        da: 'DK',
        el: 'GR',
        hr: 'HR',
        hu: 'HU',
        pl: 'PL',
        ja: 'JP',
        ru: 'RU'
      }
    }
  },

  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale
    },

    availableLocales() {
      return this.community?.availableLanguages || []
    }
  },

  methods: {
    changeLocale(locale) {
      this.$store.dispatch('setLocale', locale)
    },

    getCurrentLanguageName(locale) {
      return this.languageNames[locale] || locale.toUpperCase()
    },

    hasFlag(locale) {
      try {
        require(`@/assets/images/flags/${locale}.png`)
        return true
      } catch (e) {
        return false
      }
    },

    getEmojiFlag(locale) {
      const countryCode = this.countryFlags[locale] || locale.toUpperCase()
      return countryCode
        .toUpperCase()
        .replace(/./g, char => 
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    }
  }
}
</script>

<style lang="scss" scoped>
.language-selector {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 10;
}

.flag-icon {
  width: 20px;
  height: 15px;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 2px;
}

.flag-emoji {
  font-size: 1.2rem;
  line-height: 1;
  vertical-align: middle;
}

.lang-dropdown {
  ::v-deep .btn.btn-link {
    padding: 0.5rem 1rem;
    color: #6e6b7b;
    font-weight: 500;
    background: #f8f8f8;
    // border: 1px solid #d8d6de !important;
    border-radius: 0.357rem;
    min-width: 140px;
    text-align: left;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    box-shadow: none !important;
    text-decoration: none !important;

    &:hover, &:focus {
      background: #efefef;
      text-decoration: none !important;
      border-color: #d8d6de !important;
    }

    &:after {
      margin-left: auto;
      border-top-color: #6e6b7b;
      opacity: 1 !important;
    }
  }

  ::v-deep .dropdown-menu {
    min-width: 140px;
    padding: 0.5rem 0;
    // border: 1px solid #d8d6de !important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    margin-top: 0.5rem;
    border-radius: 0.357rem;
    background: #fff;
    overflow: hidden;
    
    .dropdown-item {
      padding: 0.5rem 1rem;
      color: #6e6b7b;
      display: flex;
      align-items: center;
      border-left: 2px solid transparent;
      background: transparent;
      
      &:hover, &:focus {
        background: #f8f8f8;
        border-left-color: var(--primary-color);
      }
      
      &.active {
        background: var(--primary-color);
        color: white;
        border-left-color: var(--primary-color);
      }
    }
  }
}

.ml-1 {
  margin-left: 0.5rem;
}
</style> 